import React, { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebase';
import { Navigate } from 'react-router-dom';
import LoadingScreen from "../components/loading-screen/LoadingScreen";

const PrivateRoute = ({ component: Component, ...rest }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setTimeout(() => {
                setIsLoading(false);
                setIsAuthenticated(!!user);
            }, 500);
        });

        return () => {
            unsubscribe();
        };
    }, []);

    if (isLoading) {
        return <LoadingScreen />;
    }

    return isAuthenticated ? <Component {...rest} /> : <Navigate to="/login" />;
};

export default PrivateRoute;
