import React from "react";
import "./BasicSelect.css";

const BasicSelect = (props) => {
    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        if (props.change) {
            props.change(selectedValue);
        }
    };

    return (
        <div className={`basic_select ${props.class}`}>
            <label className="basic_select__label">{props.label}</label>

            <select className={`basic_select__select`} defaultValue={'any'} onChange={handleSelectChange}>

                <option className="basic_select__option" value={'any'}>ANY</option>

                {props.options.map((option, index) => (
                    <option key={index} className = "basic_select__option" value={option.value}>{option.name}</option>
                    ))}

            </select>
        </div>
    );
}

export default BasicSelect;
