import React from 'react';
import './Box.css';

const Box = ({ styleName, content }) => (
    <div className={`Box box is-shadowless p-4 has-text-centered ${styleName}`}>
        <p className={'m-5 is-size-4'}>{content}</p>
    </div>
);

export default Box;
