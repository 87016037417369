import React from "react";
import "./BasicInput.css";

const BasicInput = (props) => {
    const handleInputChange = (event) => {
        const selectedValue = event.target.value;
        if (props.change) {
            props.change(selectedValue);
        }
    };

    return (
        <div className = "basic_input">
            <input className = {`basic_input__input ${props.class}`} type = {props.type} placeholder = {props.placeholder} onChange={handleInputChange} />
        </div>
    );
}

export default BasicInput;
