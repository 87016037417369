import React, { useEffect, useState } from 'react';
import './App.css';
import './components/scss/main.css';
import 'bulma/css/bulma.min.css';
import AppMain from './components/page/app-main/AppMain';
import AppAbout from './components/page/app-about/AppAbout';
import AppRules from './components/page/app-rules/AppRules';
import AppHome from './components/page/app-home/AppHome';
import AppJoin from './components/page/app-join/AppJoin';
import AppLogin from './components/page/app-login/AppLogin';
import AppRegister from './components/page/app-register/AppRegister';
import { AppProvider } from './components/utils/app-provider.js';
import PrivateRoute from './components/utils/PrivateRoute.js';
import AppLogout from './components/page/app-logout/AppLogout';
import AppDecksList from "./components/page/app-decks-list/AppDecksList";
import AppAccount from './components/page/app-account/AppAccount';
import AppDeckDetails from './components/page/app-deck-details/AppDeckDetails.js';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import LoadingScreen from "./components/components/loading-screen/LoadingScreen";
import AppDeckCreator from './components/page/app-deck-creator/AppDeckCreator.js';


const router = createBrowserRouter([
    {
        path: '/',
        element: <AppHome />,
    },
    {
        path: '/game',
        element: <AppMain />,
    },
    {
        path: '/join/:gameId',
        element: <AppMain />,
    },
    {
        path: '/join',
        element: <AppJoin />,
    },
    {
        path: '/rules',
        element: <AppRules />,
    },
    {
        path: "/decks",
        element: <AppDecksList />,
    },
    {
        path: '/about',
        element: <AppAbout />,
    },
    {
        path: '/account',
        element: <AppAccount />,
    },
    {
        path: '/login',
        element: <AppLogin />,
    },
    {
        path: '/signup',
        element: <AppRegister />,
    },
    {
        path: '/logout',
        element: <AppLogout />,
    },
    {
        path: '/deck-details/:deckId',
        element: <AppDeckDetails />,
    },
    {
        path: '/deck-creator',
        element: <PrivateRoute component={AppDeckCreator} />,
    },
]);


const App = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        });

        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <AppProvider>
            {isLoading ? (
                <LoadingScreen />
            ) : (
                <RouterProvider router={router} />
            )}
        </AppProvider>
    );
};



export default App;
