import React from 'react';
import './LanguageSwitch.css';

const LanguageSwitch = () => (
    <div className="App-menu-language">
        <select className="is-size-5" name="language" id="language-select" defaultValue={'en'}>
            <option className="is-size-5" value="en">en</option>
            <option className="is-size-5" value="pl">pl</option>
        </select>
    </div>
);

export default LanguageSwitch;
