import React from "react";
import './Button.css';
import { useNavigate } from "react-router-dom";

const Button = ({ size, text, page }) => {
    const navigate = useNavigate();

    return (
        <button className={`button ${size}`} onClick={() => navigate(page)}>
            {text}
        </button>
    );
};

export default Button;
