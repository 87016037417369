import React, { useState } from 'react';
import './AppJoin.css';
import Menu from '../../components/menu/Menu.js';
import FlashBackground from '../../elements/flash-background/FlashBackground.js';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faChevronDown, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';

const AppJoin = () => {
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleChevronClick = () => {
        navigate(`/join/${password}`);
    };

    const inputType = showPassword ? "text" : "password";

    return (
        <div className={'App-join is-unselectable'}>
            <Menu />
            <FlashBackground />
            <h1 className={"App-join--title title is-1 is-uppercase has-text-centered is-black my-2 py-5 mb-5 has-text-weight-light"}>
                Humanity Against Cards
            </h1>
            <div className={"App-join--content has-text-centered pt-5 mt-5"}>
                <h1 className={"App-join--content-title title is-2 is-uppercase has-text-centered is-black mt-3 has-text-weight-light"}>
                    Join a game
                </h1>
                <div className="control has-icons-left App-join--content-input mb-4">
                    <input
                        className="input is-large"
                        type={inputType}
                        placeholder="Code"
                        value={password}
                        onChange={handlePasswordChange}
                    />
                    <span
                        className="App-join--content-input-icon icon is-medium is-left is-clickable"
                        onClick={togglePasswordVisibility}
                    >
                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                    </span>
                    <span
                        className="App-join--content-input-icon icon is-medium is-right ml-2 is-clickable"
                        onClick={handleChevronClick}
                    >
                        <FontAwesomeIcon icon={faChevronDown} />
                    </span>
                </div>
                <h1 className={"App-join--content-subtitle title is-2 is-uppercase has-text-centered is-black mt-5 pt-5 has-text-weight-light "}>
                    HOW TO GET THE CODE?
                </h1>
                <p className={"is-black is-size-5 has-text-weight-bold"}>
                    Code can be found on the bottom of the lobby <br/> created by lobby host
                </p>
                <p className={"is-black is-size-5 has-text-weight-bold"}>
                    You can also join the game by clicking the invite <br/>link generated by lobby host
                </p>
            </div>
        </div>
    );
};

export default AppJoin;
