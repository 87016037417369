import React from "react";
import './HomeCard.css';

const HomeCard = ({ type, letter, mark, text }) => (
    <div className={`App-home-card ${type}`}>
        <span className={'letter'}> {letter} </span>
        <span className={`${mark} mark`}></span>
        <p className={'text'}> {text} </p>
    </div>
);

export default HomeCard;
