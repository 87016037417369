import React from 'react';
import './AppHome.css';
import AppTitle from '../../components/app-title/AppTitle.js';
import MainButtons from '../../components/main-buttons/MainButtons.js';
import AppHomeCards from '../app-home-cards/AppHomeCards.js';
import Menu from "../../components/menu/Menu";
import MainBackground from "../../elements/main-background/MainBackground";
import { functions } from '../../../firebase';
import { httpsCallable } from '@firebase/functions';

const sayHello = httpsCallable(functions, 'sayHello');
sayHello()
    .then((result) => {
        console.log(result);
        console.log(123);
    });

const AppHome = () => (
    <div>
        <Menu />
        <MainBackground />
        <div className="App-home container is-fluid is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
            <AppTitle />
            <AppHomeCards />
            <MainButtons />
        </div>
    </div>
);

export default AppHome;
