import React from 'react';
import './AppAccount.css';
import Menu from '../../components/menu/Menu.js';
import MainBackground from '../../elements/main-background/MainBackground.js';
import { auth } from '../../../firebase';

const AppAccount = () => {
    return (
        <div>
            <Menu />
            <MainBackground />
            <div className={'box has-background-white'}>
                <pre>
                    <code>
                        {JSON.stringify(auth.currentUser, null, 2)}
                    </code>
                </pre>
            </div>
        </div>
    );
};

export default AppAccount;
