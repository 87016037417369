import React, { useState, useEffect, useRef } from "react";
import { db, auth } from "../../../firebase";
import { collection, getDocs, addDoc } from "@firebase/firestore";
import "./AppDeckCreator.css";
import { useNavigate } from "react-router-dom";
import Menu from "../../components/menu/Menu";
import BasicInput from "../../elements/basic-input/BasicInput.js";
import BasicSelect from "../../elements/basic-select/BasicSelect.js";

const AppDeckCreator = () => {
    const navigate = useNavigate();
    const availabilityOptions = [
        {name: 'PRIVATE', value: 'private'},
        {name: 'PUBLIC', value: 'public'},
    ];

    const currentStep = useRef(null);
    const [typesOptions, setTypesOptions] = useState([]);
    const [langOptions, setLangOptions] = useState([]);
    const [selectedType, setSelectedType] = useState("any");
    const [selectedLang, setSelectedLang] = useState("any");
    const [selectedAvailability, setSelectedAvailability] = useState("any");
    const [typedInput, setTypedInput] = useState("");
    
    const whiteCardInput = useRef();
    const blackCardInput = useRef();
    const [whiteCards, setWhiteCards] = useState([]);
    const [blackCards, setBlackCards] = useState([]);
    const [numberOfWhiteCards, setNumberOfWhiteCards] = useState(0);
    const [numberOfBlackCards, setNumberOfBlackCards] = useState(0);
    
    const [randomCode, setRandomCode] = useState('');
    
    const [errorMessage, setErrorMessage] = useState('');
    
    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter' && e.target.classList.contains('white_input')) {
            handleButtonClick(whiteCardInput);
        } else if (e.key === 'Enter' && e.target.classList.contains('black_input')) {
            handleButtonClick(blackCardInput);
        }
    }

    const handleButtonClick = (input) => {
        console.log(auth.currentUser);
        const inputValue = input.current.value;

        if (inputValue.length > 0) {
            const inputClasses = input.current.classList;

            if (inputClasses.contains('black_input')) {
                setBlackCards(prevState => [...prevState, inputValue]);
                setNumberOfBlackCards(blackCards.length + 1);
            } else if (inputClasses.contains('white_input')) {
                setWhiteCards(prevState => [...prevState, inputValue]);
                setNumberOfWhiteCards(whiteCards.length + 1);
            }

            input.current.value = '';
        }
    }

    const deleteElement = (e) => {
        const elementToDelete = e.target.closest('.list_container');
        const cardText = e.target.previousElementSibling.textContent;
    
        if (elementToDelete.classList.contains('white_cards_list')) {
            const indexToDelete = whiteCards.indexOf(cardText);
            const newWhiteCards = [...whiteCards];
            newWhiteCards.splice(indexToDelete, 1);
            setWhiteCards(newWhiteCards);
            setNumberOfWhiteCards(whiteCards.length - 1);
        } else if (elementToDelete.classList.contains('black_cards_list')) {
            const indexToDelete = blackCards.indexOf(cardText);
            const newBlackCards = [...blackCards];
            newBlackCards.splice(indexToDelete, 1);
            setBlackCards(newBlackCards);
            setNumberOfBlackCards(blackCards.length - 1);
        }
    };


    const generateRandomCode = () => {
        const characters = '0123456789AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz'
        let code = '';

        for (let i = 0; i < 4; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            code += characters[randomIndex];
        }

        setRandomCode(code);
    }

    const deckValidator = () => {
        setErrorMessage('');

        if (selectedLang === 'any') {
            setErrorMessage('Choose the appropriate language for your card deck.');
        } else if (selectedType === 'any') {
            setErrorMessage('Choose the appropriate category for your card deck.');
        } else if (selectedAvailability === 'any') {
            setErrorMessage('Choose the appropriate availbility for your card deck.');
        } else if (numberOfWhiteCards === 0) {
            setErrorMessage('The number of white cards cannot be 0.');
        } else if (numberOfBlackCards === 0) {
            setErrorMessage('The number of black cards cannot be 0.');
        } else if (typedInput === '') {
            setErrorMessage('Come up with a name for your card deck.');
        } else {
            addDeckToFirestone();
            navigate(`/`);
        }
    }

    const addDeckToFirestone = async () => {
        try {
            const newDeck = {
                code: randomCode,
                displayName: typedInput,
                lang: selectedLang,
                numberOfBlackCards: numberOfBlackCards,
                numberOfWhiteCards: numberOfWhiteCards,
                type: selectedType,
                cardsBlack: [...blackCards],
                cardsWhite: [...whiteCards],
                author: auth.currentUser.uid,
                availability: selectedAvailability,
            };

            const decksCollection = collection(db, 'decks');
            await addDoc(decksCollection, newDeck);

            console.log('Added Deck');
        } catch (error) {
            console.error('Error adding deck to Firestone', error);
        }
    }

    useEffect(() => {
        generateRandomCode();
    }, []);

    useEffect(() => {
        const fetchTypes = async () => {
          try {
            const typesCollection = collection(db, "types");
            const typesSnapshot = await getDocs(typesCollection);
            const options = typesSnapshot.docs.map((doc) => ({
              name: doc.data().displayName,
              value: doc.id,
            }));
            setTypesOptions(options);
          } catch (error) {
            console.error("Error fetching types:", error);
          }
        };
  
        fetchTypes();
      }, []);


    useEffect(() => {
        const fetchLangs = async () => {
            try {
                const langCollection = collection(db, 'lang');
                const langSnapshot = await getDocs(langCollection);
                const options = langSnapshot.docs.map((doc) => ({
                    name: doc.data().displayName,
                    value: doc.id,
                }))
                setLangOptions(options);
            } catch (error) {
                console.error("Error fetching langs:", error);
            }
        };

        fetchLangs();
    }, []);

    const removeStepClass = () => {
        const containerClasses = Array.from(currentStep.current.classList);
        const containerStepClass = containerClasses.find(step => step.includes('step'));
    
        if (containerStepClass) {
            currentStep.current.classList.remove(containerStepClass);
        } 
    }

    const handleSteps = (e) => {
        if (currentStep.current) {
            removeStepClass();

            const clickedStep = e.target.classList.value;
            currentStep.current.classList.add(`${clickedStep}`);
        }
    }

    const handleButtons = (e) => {
        const containerClasses = Array.from(currentStep.current.classList);
        const containerStepClass = containerClasses.find(step => step.includes('step'));
        let nextStep = '';

        if (e.target.classList.value === 'next') {
            switch (containerStepClass) {
                case 'step-1':
                    nextStep = 'step-2';
                    break;
                case 'step-2':
                    nextStep = 'step-3';
                    break;
                default:
                    return;
            }
        } else if (e.target.classList.value === 'prev') {
            switch (containerStepClass) {
                case 'step-2':
                    nextStep = 'step-1';
                    break;
                case 'step-3':
                    nextStep = 'step-2';
                    break;
                default:
                    return;
                }
        }

        removeStepClass();
        currentStep.current.classList.add(`${nextStep}`);
    }

    const deckNameStyle = {
        WebkitBoxOrient: 'vertical',
        boxOrient: 'vertical',
        display: '-webkit-box',
    };

    return (
        <>
            <Menu />
            <div ref={currentStep} className = "deck_creator is-flex is-justify-content-center is-align-items-center is-flex-direction-column step-1">
                <div className = "deck_creator__title">
                    <h1>deck creator</h1>
                </div>

                <div className = "deck_creator__steps is-flex is-justify-content-space-between is-align-items-center">
                    <div className = "deck_creator__steps--1 is-flex is-justify-content-center is-align-items-center is-flex-direction-column">
                        <button className = "step-1" onClick={handleSteps}>1</button>
                        <span>configuration</span>
                    </div>
                    <div className = "deck_creator__steps--2 is-flex is-justify-content-center is-align-items-center is-flex-direction-column">
                        <button className = "step-2" onClick={handleSteps}>2</button>
                        <span>add cards</span>
                    </div>
                    <div className = "deck_creator__steps--3 is-flex is-justify-content-center is-align-items-center is-flex-direction-column">
                        <button className = "step-3" onClick={handleSteps}>3</button>
                        <span>summary</span>
                    </div>
                </div>

                <div className = "deck_creator__content">

                    <div className = "deck_creator__content--step-1  is-flex is-justify-content-center is-align-items-center">
                        <div className = "deck_creator__content--step-1--name">
                          <label>deck's name</label>
                          <BasicInput class="name" type="text" placeholder="ExampleDeckName" change={(value) => setTypedInput(value)} />
                        </div>

                        <div className = "deck_creator__content--step-1--type">
                          <BasicSelect class="type" label="DECK'S TYPE" options={typesOptions} change={(value) => setSelectedType(value)} />
                        </div>

                        <div className = "deck_creator__content--step-1--lang">
                          <BasicSelect class="lang" label="DECK'S LANG" options={langOptions} change={(value) => setSelectedLang(value)} />
                        </div>

                        <div className = "deck_creator__content--step-1--privacy">
                          <BasicSelect class="availability" label="DECK'S AVAILABILITY" options={availabilityOptions} change={(value) => setSelectedAvailability(value)} />
                        </div>
                    </div>

                    <div className = "deck_creator__content--step-2  is-flex is-justify-content-center is-align-items-center">
                        <div className = "white_cards">
                            <span className = "white_cards__counter">{numberOfWhiteCards}</span>
                            <div className = "white_cards__add is-flex is-justify-content-center is-align-items-center is-flex-direction-column">
                                <p>ADD WHITE CARDS</p>
                                <div className = "basic_input">
                                    <input onKeyDown={handleEnterKeyPress} ref={whiteCardInput} className = {`basic_input__input add white_input`} type='text' placeholder = 'ExampleCardName'></input>
                                </div>
                                <button onClick={() => handleButtonClick(whiteCardInput)}>ADD</button>
                            </div>
                            
                            <div className = "white_cards__list is-flex is-justify-content-center is-align-items-center is-flex-direction-column">
                                <p>CARDS LIST</p>
                                <div className = "white_cards__list__container is-flex is-flex-direction-column">
                                    {whiteCards.map((card, index) => {
                                        return (
                                            <div key={index} className = "white_cards__list__container__element list_container white_cards_list">
                                                <p>{card}</p>
                                                <span className = 'delete' onClick={deleteElement}>x</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className = "black_cards">
                            <span className = "black_cards__counter">{numberOfBlackCards}</span>
                            <div className = "black_cards__add is-flex is-justify-content-center is-align-items-center is-flex-direction-column">
                                <p>ADD BLACK CARDS</p>
                                <div className = "basic_input">
                                    <input onKeyDown={handleEnterKeyPress} ref={blackCardInput} className = {`basic_input__input add black_input`} type='text' placeholder = 'ExampleCardName'></input>
                                </div>
                                <button onClick={() => handleButtonClick(blackCardInput)}>ADD</button>
                            </div>

                            <div className = "black_cards__list is-flex is-justify-content-center is-align-items-center is-flex-direction-column">
                                <p>CARDS LIST</p>
                                <div className = "black_cards__list__container is-flex is-flex-direction-column">
                                    {blackCards.map((card, index) => {
                                        return (
                                            <div key={index} className = "black_cards__list__container__element list_container black_cards_list">
                                                <p>{card}</p>
                                                <span className = 'delete' onClick={deleteElement}>x</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className = "deck_creator__content--step-3  is-flex is-justify-content-center is-align-items-center">
                        <div className = "deck_card is-flex is-justify-content-space-between is-align-items-center is-flex-direction-column">
                            <div className = "deck_card__code">
                                <span>#{randomCode}</span>
                            </div>

                            <div className = "deck_card_body">
                                <div className = "deck_card_body__name">
                                    <p style={deckNameStyle}>{typedInput}</p>
                                </div>

                                <div className = "deck_card_body__description">
                                    <div className = "deck_card_body__description__cards">
                                        <span className="deck_card_body__description__cards--black">{numberOfBlackCards}</span>
                                        <span className="deck_card_body__description__cards--white">{numberOfWhiteCards}</span>
                                    </div>

                                    <div className = "deck_card_body__description__type">
                                        <span className="deck_card_body__description__type--lang is-uppercase">{selectedLang}</span>
                                        <span className={`deck_card_body__description__type--category ${selectedType}`}></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className='error'>{errorMessage}</p>
                    </div>

                </div>

                <div className = "deck_creator__buttons is-flex is-justify-content-space-between is-align-items-center">
                    <div className = "deck_creator__buttons__left">
                        <button className = "prev" onClick={handleButtons}>previous</button>
                    </div>

                    <div className = "deck_creator__buttons__right is-flex is-align-items-center is-justify-content-flex-end">
                        <button className = "next" onClick={handleButtons}>next</button>
                        <button className = "create" onClick={deckValidator}>create</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AppDeckCreator;