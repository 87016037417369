import React, { useState } from 'react';
import { createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from 'firebase/auth';
import { auth } from '../../../firebase';
import { useNavigate } from 'react-router-dom';
import './RegisterForm.css';

const RegisterForm = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [terms, setTerms] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [emailError, setEmailError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);
    const [nameError] = useState(null);
    const [termsError, setTermsError] = useState(null);

    const handleAuthError = (errorCode) => {
        switch (errorCode) {
            case 'auth/invalid-email':
                setEmailError('This email is invalid');
                break;
            case 'auth/wrong-password':
                setPasswordError('Wrong password');
                break;
            case 'auth/missing-password':
                setPasswordError('Missing password');
                break;
            case 'auth/too-many-requests':
                setPasswordError('Too many requests');
                setEmailError(' ');
                break;
            case 'auth/invalid-login-credentials':
                setPasswordError('Invalid login credentials');
                setEmailError(' ');
                break;
            default:
                break;
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        if (!terms) {
            setTermsError('You must accept the terms and conditions');
        } else {
            try {
                await createUserWithEmailAndPassword(auth, email, password);
                await sendEmailVerification(auth.currentUser)
                await updateProfile(auth.currentUser, { displayName: name })
                navigate("/login");
            } catch (error) {
                const errorCode = error.code;
                handleAuthError(errorCode);
            }
        }
    };

    return (
        <form className={'RegisterForm'}>
            <div className={`field ${nameError ? 'has-danger' : ''}`}>
                <label className="label">Name</label>
                <div className="control">
                    <input
                        className={`input ${nameError ? 'is-danger' : ''}`}
                        id="name"
                        name="name"
                        type="text"
                        required
                        placeholder="Name"
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                {nameError && <p className="help is-danger">{nameError}</p>}
            </div>

            <div className={`field ${emailError ? 'has-danger' : ''}`}>
                <label className="label">Email</label>
                <div className="control">
                    <input
                        className={`input ${emailError ? 'is-danger' : ''}`}
                        id="email-address"
                        name="email"
                        type="email"
                        required
                        placeholder="Email address"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                {emailError && <p className="help is-danger">{emailError}</p>}
            </div>

            <div className={`field mt-3 mb-5 ${passwordError ? 'has-danger' : ''}`}>
                <label className="label">Password</label>
                <div className="control">
                    <input
                        className={`input ${passwordError ? 'is-danger' : ''}`}
                        id="password"
                        name="password"
                        type="password"
                        required
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                {passwordError && <p className="help is-danger">{passwordError}</p>}
            </div>

            <div className="field">
                <div className="control">
                    <label className={`checkbox ${termsError ? 'has-danger' : ''}`}>
                        <input type="checkbox" onChange={(e) => setTerms(e.target.value)} />
                        <span className={'pl-3'}>
                            I agree to the terms and conditions
                        </span>
                    </label>
                </div>
                {termsError && <p className="help is-danger">{termsError}</p>}
            </div>

            <button className={'RegisterForm--button is-clickable mt-5'} onClick={onSubmit}>
                Register
            </button>
        </form>
    );
};

export default RegisterForm;
