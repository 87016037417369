import { React, useEffect, useState } from "react";
import "./AppDecksList.css";
import Menu from "../../components/menu/Menu.js";
import MainBackground from "../../elements/main-background/MainBackground";
import BasicInput from "../../elements/basic-input/BasicInput.js";
import BasicSelect from "../../elements/basic-select/BasicSelect.js";
import DeckListCard from "../../components/deck-list-card/DeckListCard.js";
import { db } from "../../../firebase";
import { collection, getDocs } from "@firebase/firestore";

const AppDecksList = () => {
    const [typesOptions, setTypesOptions] = useState([]);
    const [langOptions, setLangOptions] = useState([]);
    const [selectedTyppe, setSelectedType] = useState("any");
    const [selectedLang, setSelectedLang] = useState("any");
    const [typedInput, setTypedInput] = useState("");

    useEffect(() => {
      const fetchTypes = async () => {
        try {
          const typesCollection = collection(db, "types");
          const typesSnapshot = await getDocs(typesCollection);
          const options = typesSnapshot.docs.map((doc) => ({
            name: doc.data().displayName,
            value: doc.id,
          }));
          setTypesOptions(options);
        } catch (error) {
          console.error("Error fetching types:", error);
        }
      };

      fetchTypes();
    }, []);

    useEffect(() => {
        const fetchLang = async () => {
          try {
            const typesCollection = collection(db, "lang");
            const typesSnapshot = await getDocs(typesCollection);
            const options = typesSnapshot.docs.map((doc) => ({
              name: doc.data().displayName,
              value: doc.id,
            }));
            setLangOptions(options);
          } catch (error) {
            console.error("Error fetching lang:", error);
          }
        };

        fetchLang();
      }, []);


    return (
        <div className = "decks_list">
            <Menu />
            <MainBackground />
            <div className = "decks_list__content">
                <div className= "decks_list__content--filters is-flex is-justify-content-space-between is-align-items-flex-end mx-5 my-2">
                    <BasicInput class="search" type="search" placeholder="SEARCH BY NAME" change={(value) => setTypedInput(value)} />

                    <div className = "decks_list__content--filters--sort is-flex is-justify-content-space-between is-align-items-center">
                        <BasicSelect class="sort" label="TYPE" options={typesOptions} change={(value) => setSelectedType(value)} />

                        <BasicSelect class="sort" label="LANG" options={langOptions} change={(value) => setSelectedLang(value)} />
                    </div>

                </div>

                <div className = "decks_list__content--list">
                        <DeckListCard
                            selectedLang={selectedLang}
                            selectedType={selectedTyppe}
                            typedInput={typedInput}
                        />
                </div>
            </div>
        </div>
    );
}

export default AppDecksList;
