import React, { useContext, useEffect } from 'react';
import './LogoLight.css';
import { AppContext } from '../../utils/app-provider';
import { useNavigate } from 'react-router-dom';

const LogoLight = () => {
    const { handleViewChange } = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        const isActive = ['/', ''].includes(window.location.pathname);
        if (isActive) handleViewChange();
    }, [handleViewChange]);

    const logo = {
        imageUrl: '/assets/logo-light.png',
        imageAlt: 'Humanity Against Cards logo',
        imageSize: '50px',
    };

    const handleLogoClick = () => {
        navigate('/');
        handleViewChange();
    };

    return (
        <div className="App-menu-logo" style={{ width: logo.imageSize, height: logo.imageSize }}>
            <button className="App-menu-logo__link is-clickable" onClick={handleLogoClick}>
                <img src={logo.imageUrl} alt={logo.imageAlt} />
            </button>
        </div>
    );
};

export default LogoLight;
