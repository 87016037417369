import React from 'react';
import './AppMain.css';
import Menu from '../../components/menu/Menu.js';
import MainBackground from '../../elements/main-background/MainBackground.js';

const AppMain = () => {
    return (
        <div>
            <Menu />
            <MainBackground />
        </div>
    );
};

export default AppMain;
