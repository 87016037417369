import React, { createContext, useState } from 'react';

const Page = {
    Home: '/',
    NewGame: '/game',
    Join: '/join',
    Rules: '/rules',
    Decks: '/decks',
    About: '/about',
    Account: '/account',
    Login: '/login',
    Logout: '/logout',
};
const AppContext = createContext(Page.Home);

const AppProvider = ({ children }) => {
    const [currentView, setCurrentView] = useState(Page.Home);

    const handleViewChange = (newView) => {
        setCurrentView(newView);
    };

    return (
        <AppContext.Provider value={{ currentView, handleViewChange }}>
            {children}
        </AppContext.Provider>
    );
};

export { AppProvider, AppContext, Page };
