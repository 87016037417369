import React from 'react';
import './Menu.css';
import LogoLight from '../logo-light/LogoLight.js';
import MainMenu from '../main-menu/MainMenu.js';
import LanguageSwitch from '../language-switch/LanguageSwitch.js';

const Menu = () => (
    <div className="App-menu container is-fluid is-flex is-justify-content-space-between is-align-items-center">
        <LogoLight />
        <MainMenu />
        <LanguageSwitch />
    </div>
);

export default Menu;
