import React from 'react';
import './AppAbout.css';
import Menu from '../../components/menu/Menu.js';
import MainBackground from '../../elements/main-background/MainBackground.js';
import Box from '../../elements/box/Box';
import Creator from '../../components/creator/Creator';

const AppAbout = () => {
    return (
        <div className={'App-about'}>
            <Menu />
            <MainBackground />
            <div className={'App-about--main pt-5'}>
                <Box styleName={'p-4 has-text-centered mt-5'} content={'Humanity Against Cards is a captivating card game based on the popular Cards Against Humanity format but with a unique twist. This open-source and free-to-play game offers unforgettable experiences by combining dark humor with players\' creativity.'} />
                <div className="App-about--main-creators is-fullwidth">
                    <h1 className={"title is-1 has-text-weight-light has-text-centered"}>CREATORS</h1>
                    <div className={"columns mt-2"}>
                        <Creator
                            name={'Michal'}
                            img={'/assets/creators/michal.png'}
                            github={'https://github.com/M-Gzella'}
                            linkedin={'https://www.linkedin.com/in/micha%C5%82-gzella-554243291/'}
                            styleName={'column'}
                        />
                        <Creator
                            name={'Mikolaj'}
                            img={'/assets/creators/mikolaj.png'}
                            github={'https://github.com/mbrumir'}
                            linkedin={'https://www.linkedin.com/in/mikołaj-brumirski-405871228/'}
                            styleName={'column'}
                        />
                        <Creator
                            name={'Kuba'}
                            img={'/assets/creators/kuba.png'}
                            github={'https://github.com/JakubSwiatkowski'}
                            linkedin={'https://linkedin.pl/ja'}
                            styleName={'column'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppAbout;
