import React from 'react';
import './MainMenu.css';
import MenuLink from '../menu-link/MenuLink.js';
import { Page } from '../../utils/app-provider.js';
import { auth } from '../../../firebase';

const MainMenu = () => {
    const isLogged = !!auth.currentUser;

    return (
        <div className="App-menu-content">
            <ul className="App-menu-content__list is-flex columns">
                <MenuLink text="create game" page={Page.NewGame}/>
                <MenuLink text="join" page={Page.Join}/>
                <MenuLink text="rules" page={Page.Rules}/>
                <MenuLink text="decks" page={Page.Decks}/>
                <MenuLink text="about" page={Page.About}/>
                {isLogged ?
                    <MenuLink text="account" page={Page.Account}/> :
                    <MenuLink text="login" page={Page.Login}/>
                }
                {isLogged ? <MenuLink text="logout" page={Page.Logout}/> : null}
            </ul>
        </div>
    );
};

export default MainMenu;
