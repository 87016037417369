import React from "react";
import './AppTitle.css';

const AppTitle = () => (
    <div className="App-title">
        <h1>Humanity Against Cards</h1>
    </div>
);

export default AppTitle;
