import React, { useContext } from 'react';
import { AppContext } from '../../utils/app-provider';
import './MenuLink.css';
import { useNavigate } from 'react-router-dom';

const MenuLink = ({ text, page }) => {
    const { handleViewChange } = useContext(AppContext);
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(page);
        handleViewChange(page);
    };

    const isActive = window.location.pathname.includes(page);

    return (
        <button
            className={`App-menu-content__list__item column is-size-5 ${isActive ? 'active' : ''}`}
            onClick={handleClick}
        >
            {text}
        </button>
    );
};

export default MenuLink;
