// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getFirestore } from "firebase/firestore";
import { getFunctions } from 'firebase/functions';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCIHvYZj3ZHf12whw2OxsucOzOuuY1V4Tk",
    authDomain: "humanityagainstcards-1b055.firebaseapp.com",
    projectId: "humanityagainstcards-1b055",
    storageBucket: "humanityagainstcards-1b055.appspot.com",
    messagingSenderId: "555812283598",
    appId: "1:555812283598:web:727e9875d2f1d416c25c9f",
    measurementId: "G-HFH8NK9N98"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const auth = getAuth(app);

const db = getFirestore(app);
const functions = getFunctions(app);

export { auth, db, functions };
