import React from 'react';
import './Creator.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Creator = ({ img, name, styleName, github, linkedin }) => (
    <div className={`Creator mx-5 px-5 ${styleName}`}>
        <img className={"Creator--img"} src={img} alt={name} />
        <h2 className={"Creator--name subtitle is-2 pt-3 mt-5 mb-2 is-uppercase is-black"}>{name}</h2>
        <div className={"Creator--socials"}>
            <a className={"Creator--socials-github"} href={github} target={"_blank"} rel={"noopener noreferrer"}>
                <FontAwesomeIcon icon={faGithub} className={'is-black mx-3'} size="3x" />
            </a>
            <a className={"Creator--socials-linkedin"} href={linkedin} target={"_blank"} rel={"noopener noreferrer"}>
                <FontAwesomeIcon icon={faLinkedin} className={'is-black mx-3'} size="3x" />
            </a>
        </div>
    </div>
);

export default Creator;
