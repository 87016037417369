import React from "react";
import './MainButtons.css';
import Button from "../../elements/button/Button";
import { Page } from '../../utils/app-provider.js';

const MainButtons = () => (
    <div className="main-buttons container is-fluid is-flex is-align-items-flex-end is-justify-content-space-around">
        <Button text="decks" page={Page.Decks} size="regular" />
        <Button text="create game" page={Page.NewGame} size="bigger" />
        <Button text="join" page={Page.Join} size="regular" />
    </div>
);

export default MainButtons;
