import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import { collection, getDocs, where, query, limit } from "@firebase/firestore";
import { useParams } from "react-router-dom";
import './AppDeckDetails.css';
import MainBackground from "../../elements/main-background/MainBackground";
import Menu from "../../components/menu/Menu";

const DeckDetails = () => {
    const { deckId } = useParams();
    const [deckDetails, setDeckDetails] = useState([]);

    useEffect(() => {
        const fetchDecks = async () => {
          try {
              let decksCollection = collection(db, "decks");
              let q = query(
                decksCollection,
                where("code", "==", deckId),
                limit(1),
                );

              const typesSnapshot = await getDocs(q);
              setDeckDetails(typesSnapshot.docs.map((doc) => doc.data()));
          } catch (error) {
            console.error("Error fetching decks:", error);
          }
        };

        fetchDecks();
      }, [deckId]);

      return (
        <>
            <Menu />
            <MainBackground />
            {deckDetails.map((deck, index) => {
                return (
                    <div key={index} className = "details_container is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-center">
                        <div className = "details_container__top is-flex is-justify-content-space-between is-align-items-center">
                            <div className = "details_container__top--lang">
                              <span>{deck.lang}</span>
                            </div>
                            <div className = "details_container__top--type">
                              <span>{deck.type}</span>
                            </div>
                            <div className = "details_container__top--code">
                              <span>#{deck.code}</span>
                            </div>
                        </div>

                        <div className = "details_container__middle is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
                            <div className = "details_container__middle--name">
                              <span>{deck.displayName}</span>
                            </div>
                            <div className = "details_container__middle--author">
                              <span>lysyhuj</span>
                            </div>
                        </div>

                        <div className = "details_container__bottom is-flex is-justify-content-center is-align-items-flex-start">
                            <div className = "details_container__bottom--white">
                                <div className = "details_container__bottom--white--title">
                                  <span>WHITE</span>
                                </div>
                                <div className = "details_container__bottom--white--cards is-flex is-justify-content-flex-start is-flex-direction-column is-align-items-center">
                                  {deck.cardsWhite.map((card) => {
                                    return (
                                      <span>{card}</span>
                                    )
                                  })}
                                </div>
                            </div>

                            <div className = "details_container__bottom--black">
                                <div className = "details_container__bottom--black--title">
                                  <span>BLACK</span>
                                </div>
                                <div className = "details_container__bottom--black--cards is-flex is-justify-content-flex-start is-flex-direction-column is-align-items-center">
                                {deck.cardsBlack.map((card) => {
                                    return (
                                      <span>{card}</span>
                                    )
                                  })}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
      );
}

export default DeckDetails;