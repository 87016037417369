import React from 'react';
import './AppRules.css';
import Menu from '../../components/menu/Menu';
import MainBackground from '../../elements/main-background/MainBackground';

const AppRules = () => {
    return (
        <div className={'App-rules'}>
            <Menu />
            <MainBackground />
            <div className={'App-rules--main box is-shadowless p-4 has-text-centered'}>
                <h1 className={'title is-1 pb-4 has-text-weight-light'}>How to play?</h1>
                <p className={'m-5 is-size-5'}>
                    To start the game, each player draws five White Cards. The person chosen by lobby host begins as the Card Czar and draws a Black Card. The Card Czar reads the question or fill-in-the-blank phrase on the Black card out loud. Everyone else answers the question or fills in the blank by picking one (sometimes two or more) White Card. After reaching the time limit, the White Cards are shuffled and handed to the Card Czar to be read out loud. The Card Czar then has to pick the funniest card, and whoever submitted the funniest card gets the point. After the round, the next Card Czar is randomly chosen from the remaining players, and the game continues. The game ends when a player reaches the agreed-upon number of points (stated in game settings) or when the deck has no more cards to deal from.
                </p>
                <h2 className={'title is-2 py-4 has-text-weight-light'}>
                    <span className={"is-purple"}>TWITCH </span>
                    <span>INTEGRATION</span>
                </h2>
                <p className={'m-5 is-size-5'}>
                    With Twitch integration, every chat member can vote for the funniest card when all the White Cards become visible. To vote for the specific card, a chat member has to enter the number in Twitch chat (counted from left to right) of the specific card they want to vote for. For example, a chat member puts “1” in the streamer's chat, and that counts as a vote for the first card (counted from left to right). After 20 seconds, the vote closes. Despite the vote, the Card Czar can still choose a different card as the funniest, as the vote is only a suggestion for the streamer.
                </p>
            </div>
        </div>
    );
};

export default AppRules;
