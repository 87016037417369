import React from "react";
import { usePagination } from "./usePagination.js";
import "./Pagination.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

const DOTS = "...";

const Pagination = (props) => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];

    return (
        <div className="pagination-container">
            <button
                className={`pagination-item arrow_left ${
                    currentPage === 1 ? "disabled" : ""
                }`}
                onClick={onPrevious}
            >
                <span><FontAwesomeIcon icon={faChevronLeft} /></span>
            </button>
            {paginationRange.map((pageNumber, index) => {
                if (pageNumber === DOTS) {
                    return <span className="pagination-item dots">&#8230;</span>;
                }

                return (
                    <button
                        key={index}
                        className={`pagination-item ${
                            currentPage === pageNumber ? "active" : null
                        }`}
                        onClick={() => onPageChange(pageNumber)}
                    >
                        {pageNumber}
                    </button>
                );
            })}
            <button
                className={`pagination-item arrow_right ${
                    currentPage === lastPage ? "disabled" : ""
                }`}
                onClick={onNext}
            >
                <span><FontAwesomeIcon icon={faChevronRight} /></span>
            </button>
        </div>
    );
}

export default Pagination;