import React, { useState } from 'react';
import { signInWithEmailAndPassword, setPersistence, browserLocalPersistence, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../../../firebase';
import { useNavigate } from 'react-router-dom';
import './LoginForm.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGoogle} from "@fortawesome/free-brands-svg-icons";

const LoginForm = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);

    const handleAuthError = (errorCode) => {
        switch (errorCode) {
            case 'auth/invalid-email':
                setEmailError('This email is invalid');
                break;
            case 'auth/wrong-password':
                setPasswordError('Wrong password');
                break;
            case 'auth/missing-password':
                setPasswordError('Missing password');
                break;
            case 'auth/too-many-requests':
                setPasswordError('Too many requests');
                setEmailError(' ');
                break;
            case 'auth/invalid-login-credentials':
                setPasswordError('Invalid login credentials');
                setEmailError(' ');
                break;
            default:
                break;
        }
    };

    const onLogin = async (e) => {
        e.preventDefault();

        setEmailError(null);
        setPasswordError(null);

        try {
            await setPersistence(auth, browserLocalPersistence);
            await signInWithEmailAndPassword(auth, email, password);
            navigate('/');
        } catch (error) {
            const errorCode = error.code;
            handleAuthError(errorCode);
        }
    };

    const onGoogleLogin = async () => {
        try {
            const provider = new GoogleAuthProvider();
            await setPersistence(auth, browserLocalPersistence);
            await signInWithPopup(auth, provider);
            navigate('/');
        } catch (error) {
            console.error('Google login error:', error);
        }
    };

    return (
        <form className={'LoginForm'}>
            <div className={`field ${emailError ? 'has-danger' : ''}`}>
                <label className="label">Email</label>
                <div className="control">
                    <input
                        className={`input ${emailError ? 'is-danger' : ''}`}
                        id="email-address"
                        name="email"
                        type="email"
                        required
                        placeholder="Email address"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                {emailError && <p className="help is-danger">{emailError}</p>}
            </div>

            <div className={`field mt-3 mb-5 ${passwordError ? 'has-danger' : ''}`}>
                <label className="label">Password</label>
                <div className="control">
                    <input
                        className={`input ${passwordError ? 'is-danger' : ''}`}
                        id="password"
                        name="password"
                        type="password"
                        required
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                {passwordError && <p className="help is-danger">{passwordError}</p>}
            </div>

            <button className={'LoginForm--button is-clickable mt-5'} onClick={onLogin}>
                Login
            </button>
            <div className={'LoginForm--other-providers'}>
                <span>or login with</span>
            </div>
            <button className={'LoginForm--button is-clickable google'} onClick={onGoogleLogin}>
                <FontAwesomeIcon icon={faGoogle} className={'mr-2'} />
                Google
            </button>
        </form>
    );
};

export default LoginForm;
