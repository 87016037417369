import React from "react";
import './AppHomeCards.css';
import HomeCard from "../../components/home-card/HomeCard";

const AppHomeCards = () => (
    <div className="App-home-cards is-flex is-justify-content-center is-align-items-center">
        <HomeCard type="first" letter="K" mark="club" text="i got 99 problems but ____ ain't one"/>
        <HomeCard type="second" letter="Q" mark="diamond" text="testicular torision."/>
        <HomeCard type="third" letter="A" mark="heart" text="Humanity Against Cards is an engaging party game designed to transform your awkward personality and lackluster social skills into hours of entertainment! The rules are straightforward."/>
        <HomeCard type="fourth" letter="K" mark="diamond" text="grave robbing"/>
        <HomeCard type="fifth" letter="J" mark="spade" text="during ___ i like to think about __________"/>
        <HomeCard type="sixth" letter="A" mark="club" text=""/>
        <HomeCard type="seventh" letter="J" mark="spade" text=""/>
    </div>
);

export default AppHomeCards;
