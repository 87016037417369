import React, { useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../../firebase';
import {NavLink, useNavigate} from 'react-router-dom';
import Menu from '../../components/menu/Menu.js';
import FlashBackground from '../../elements/flash-background/FlashBackground';
import AppTitle from '../../components/app-title/AppTitle';
import RegisterForm from '../../form/register-form/RegisterForm';
import './AppRegister.css';

const AppRegister = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            console.log('user', auth.currentUser);
            if (user) {
                navigate('/');
            }
        });

        return () => {
            unsubscribe();
        };
    }, [navigate]);

    return (
        <div>
            <Menu />
            <FlashBackground />
            <AppTitle />
            <div className={'App-login--main box is-shadowless p-5 has-text-centered p-5'}>
                <h3 className={'App-login--main_title subtitle is-3 mb-0'}>Create account</h3>
                <hr className={'my-2 has-background-black'}/>
                <p className={'App-login--main_text mb-4'}>
                    Already have an account? <NavLink to="/login" className={''}>Sign in</NavLink>
                </p>
                <RegisterForm />
            </div>
        </div>
    );
};

export default AppRegister;
