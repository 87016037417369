import React, { useState, useMemo, useEffect } from "react";
import { db } from "../../../firebase";
import { collection, getDocs, where, query } from "@firebase/firestore";
import Pagination from "../pagination/Pagination.js";
import "./DeckListCard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";


const DeckListCard = ({ selectedLang, selectedType, typedInput }) => {
    const PageSize = 7;
    const [currentPage, setCurrentPage] = useState(1);
    const [decks, setDecks] = useState([]);

    const currentDecksData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return decks.slice(firstPageIndex, lastPageIndex);
    }, [decks, currentPage]);

    const deckNameStyle = {
        WebkitBoxOrient: 'vertical',
        boxOrient: 'vertical',
        display: '-webkit-box',
      };


      const handleAddDeck = () => {
        navigate(`/deck-creator`);
      }

      useEffect(() => {
        const fetchDecks = async () => {
          try {
              let decksCollection = collection(db, "decks");
              let q = query(
                        decksCollection,
                        where("availability", "==", "public")
                        );

              switch (true) {
                  case typedInput !== "":
                      q = query(
                          decksCollection,
                          where("displayName", ">=", typedInput),
                          where("displayName", "<=", typedInput + "\uf8ff"),
                          where("availability", "==", "public")
                      );
                      break;
                  case selectedType !== "any" && selectedLang !== "any":
                      q = query(
                          decksCollection,
                          where("type", "==", selectedType),
                          where("lang", "==", selectedLang.toUpperCase()),
                          where("availability", "==", "public")
                      );
                      break;
                  case selectedType !== "any":
                      q = query(
                          decksCollection,
                          where("type", "==", selectedType),
                          where("availability", "==", "public")
                      );
                      break;
                  case selectedLang !== "any":
                      q = query(
                          decksCollection,
                          where("lang", "==", selectedLang.toUpperCase()),
                          where("availability", "==", "public")
                      );
                      break;
                  default:
                      break;
              }

              const typesSnapshot = await getDocs(q);

            setDecks(typesSnapshot.docs.map((doc) => doc.data()));
          } catch (error) {
            console.error("Error fetching decks:", error);
          }
        };

        fetchDecks();
      }, [selectedLang, selectedType, typedInput]);

      const navigate = useNavigate();

      const handleDeckClick = (deckId) => {
        navigate(`/deck-details/${deckId}`)
      }

    return (
        <>
            <div onClick={handleAddDeck} className = "deck_card deck_card_add is-flex is-justify-content-space-between is-align-items-center is-flex-direction-column">
                <div className = "deck_card__code">
                    <span>#XXXX</span>
                </div>

                <div className = "deck_card__name">
                    <p style={deckNameStyle}>+ ADD NEW</p>
                </div>
            </div>

            {currentDecksData.map((deck, index) => {
                return (
                    <div key={index} className = "deck_card is-flex is-justify-content-space-between is-align-items-center is-flex-direction-column">
                        <div className = "deck_card__code">
                            <span onClick={(e) => {navigator.clipboard.writeText(e.target.textContent)}}>{deck.code}</span>
                            <FontAwesomeIcon icon={faCopy} />
                        </div>

                        <div className = "deck_card_body" onClick={() => handleDeckClick(deck.code)}>
                            <div className = "deck_card_body__name">
                                <p style={deckNameStyle}>{deck.displayName}</p>
                            </div>

                            <div className = "deck_card_body__description">
                                <div className = "deck_card_body__description__cards">
                                    <span className="deck_card_body__description__cards--black">{deck.numberOfBlackCards}</span>
                                    <span className="deck_card_body__description__cards--white">{deck.numberOfWhiteCards}</span>
                                </div>

                                <div className = "deck_card_body__description__type">
                                    <span className="deck_card_body__description__type--lang">{deck.lang}</span>
                                    <span className={`deck_card_body__description__type--category ${deck.type}`}></span>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={decks.length}
                    pageSize={PageSize}
                    onPageChange={page => setCurrentPage(page)}
                />
        </>
    );
}

export default DeckListCard;
